
export const announcementTableConf = [
	{
		label: '标题',
		prop: '1'
	},
	{
		label: '公告类型',
		prop: '2'
	},
	{
		label: '公告内容',
		prop: '3'
	},
	{
		label: '操作',
		prop: 'operation'
	}
]