<template>
	<div>
		<e-table :tableCols="announcementTableConf" :showOperation="true" :dataOrigin="dataOrigin">
			<template #operationArea>
				<div class="flex_r f_jc_b">
					<div><el-button type="primary" @click="$refs.dialogRef.handleOpen(); isAdd = true;">添加</el-button></div>
					<el-form label-width="auto" inline>
						<el-form-item label="指派对象">
							<el-select>
								<el-option label="1" value="1"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="关键字">
							<el-input></el-input>
						</el-form-item>
						<el-button type="primary">搜索</el-button>
						<el-button>重置</el-button>
					</el-form>
				</div>
				<el-tabs v-model="activeName" @tab-click="handleClick">
					<el-tab-pane label="全部" name="1"></el-tab-pane>
					<el-tab-pane label="活动通知" name="2"></el-tab-pane>
					<el-tab-pane label="招商任务" name="3"></el-tab-pane>
					<el-tab-pane label="其他" name="4"></el-tab-pane>
				</el-tabs>
			</template>
			<template #operation>
				<el-button type="primary" @click="$refs.dialogRef.handleOpen();isAdd = false;">编辑</el-button>
				<el-button type="danger">删除</el-button>
			</template>
		</e-table>
		<e-dialog :title="isAdd ? '新增公告' : '编辑公告'" width="500px" ref="dialogRef">
			<el-form label-width="auto">
				<el-form-item label="公告类型">
					<el-select>
						<el-option label="1" value="1"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="标题">
					<el-input></el-input>
				</el-form-item>
				<el-form-item label="内容">
					<el-input type="textarea" :rows="8"></el-input>
				</el-form-item>
			</el-form>
		</e-dialog>
	</div>
</template>

<script>
import {announcementTableConf} from '@data/system/announcement'
export default {
	name: 'Announcement',
	data() {
		return {
			announcementTableConf,
			isAdd: true,
			activeName: '1',
			dataOrigin: {
				data: [
					{
						1: '123',
						2: '123',
						3: '123',
					}
				]
			}
		}
	},
	methods: {
		handleClick() {
		}
	}
}
</script>